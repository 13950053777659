import { PresetImageDTO } from 'api/api_code'
import { SHIELD } from 'components/UI/Icon/icons/illustration.constants'
import { SPORT } from 'constants/sports'
import { KeyboardEvent } from 'react'

export const get = (obj, path, defValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
  // Find value if exist return otherwise return undefined value;
  return pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj) ?? defValue
}

const preventDragOffCanvas = (obj) => {
  const offset = 5
  const objBoundingRect = obj.getBoundingRect(true)

  // Y coordinates
  if (objBoundingRect.top <= offset) {
    // Object top exceeding canvas from the top
    obj.top = offset
  } else if (objBoundingRect.top + objBoundingRect.height > obj.canvas.height - offset) {
    // Object bottom exceeding canvas from the bottom
    obj.top =
      Math.min(
        obj.top,
        obj.canvas.height - objBoundingRect.height + obj.top - objBoundingRect.top
      ) - offset
  }

  // X coordinates
  if (objBoundingRect.left <= offset) {
    // Object left exceeding canvas from the left
    obj.left = offset
  } else if (objBoundingRect.left + objBoundingRect.width > obj.canvas.width - offset) {
    // Object right exceeding canvas from the right
    obj.left =
      Math.min(
        obj.left,
        obj.canvas.width - objBoundingRect.width + obj.left - objBoundingRect.left
      ) - offset
  }
}

const preventScalingOffCanvas = (obj) => {
  const offset = 5
  const objBoundingRect = obj.getBoundingRect(true)

  if (objBoundingRect.top + objBoundingRect.height > obj.canvas.height - offset) {
    obj.scaleY = (obj.canvas.height - offset * 2 - obj.top) / obj.height
  }
  if (objBoundingRect.left + objBoundingRect.width > obj.canvas.width - offset) {
    obj.scaleX = (obj.canvas.width - offset * 2 - obj.left) / obj.width
  }
}

export const checkLimits = (obj) => {
  // Check positioning
  preventDragOffCanvas(obj)
  // Check scaling
  preventScalingOffCanvas(obj)
}

/**
 * Returns the four points of the rectangle normalized
 * @param e event from canvas object
 * @param {number} width Width of canvas
 * @param {number} height Height of canvas
 */
export const getObjectCanvasValues = (e, width: number, height: number) => {
  const obj = e.target
  return [
    { x: obj.left / width, y: obj.top / height },
    { x: (obj.left + obj.width * obj.scaleX) / width, y: obj.top / height },
    {
      x: (obj.left + obj.width * obj.scaleX) / width,
      y: (obj.top + obj.height * obj.scaleY) / height,
    },
    { x: obj.left / width, y: (obj.top + obj.height * obj.scaleY) / height },
  ]
}

export const normalizePoint = (x: number, y: number, width: number, height: number) => {
  return {
    x: x / width,
    y: y / height,
  }
}

export const deNormalizePoint = (x: number, y: number, width: number, height: number) => {
  return {
    x: x * width,
    y: y * height,
  }
}

export const getObjectCanvasCalculatedValues = (obj) => {
  return {
    image_uuid: obj.id,
    position: {
      x: obj.left,
      y: obj.top,
      width: obj.width * obj.scaleX,
      height: obj.height * obj.scaleY,
    },
    image: { base64: obj.base64, width: obj.width * obj.scaleX, height: obj.height * obj.scaleY },
    name: `name-${obj.id}`,
  } as PresetImageDTO
}

export const getBase64 = (
  file
): Promise<{ base64: string; format: string; width: number; height: number }> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const img = new Image()
      img.onload = function () {
        resolve({
          base64: (reader.result as string).replace('data:', '').replace(/^.+,/, ''),
          format: file.type,
          width: img.width,
          height: img.height,
        })
      }
      img.src = reader.result as string
    }
  })
}

export const getFabricBorderStyle = () => {
  return {
    hasBorders: true,
    borderColor: 'white',
    borderDashArray: [5, 4],
    lockRotation: true,
    transparentCorners: false,
    cornerColor: '#FA9900',
    cornerSize: 8,
    borderOpacityWhenMoving: 1,
  }
}

export const getSportIllus = (key) => {
  if (key in SPORT) return SPORT[key].illustration
  return SHIELD
}

export const getBackgroundImage = (uuid) => {
  if (uuid in SPORT) return SPORT[uuid].background
  return 'summaryBackground.jpg'
}

export const getSportName = (uuid) => {
  if (uuid in SPORT) return SPORT[uuid].name
  return 'Sport'
}

export const getSportId = (uuid) => {
  if (uuid in SPORT) return SPORT[uuid].id
}

export const snakeCaseToTitleCase = (record) => {
  const titleCased = record.status
    .split('_')
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ')
  return titleCased
}

export const getRegexPattern = (searchString: string) => {
  const symbols = /[-\[\]{}()*+?.,\\^$|#]/g
  const hasSymbols = symbols.test(searchString)

  if (hasSymbols) {
    const searchStringWithSymbols = searchString.replace(symbols, '\\$&')
    return new RegExp(`${searchStringWithSymbols}\ \\((\\d)\\)`, 's')
  }

  return new RegExp(`${searchString}\ \\((\\d)\\)`, 's')
}
export const validateOnlyNumbers = (e: KeyboardEvent<HTMLInputElement>) => {
  if (!/\d/.test(e.key) && e.key !== 'Backspace') {
    e.preventDefault()
  }
}
